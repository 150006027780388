import { CometChat } from '@cometchat-pro/chat';
import { createGlobalStyle } from 'styled-components';
import { ConnectedBreadcrumb } from './components/Breadcrumbs/ConnectedBreadcrumb';
import { IPatient, IStoreItem, PHRTrackerWeightHeightDTO, SendToEnum } from './generated';
import { OutpostTheme } from './themes/outpost';

export enum ChatSettingsEnum {
  REACT_APP_COMETCHAT_REGION = 'us',
  REACT_APP_COMETCHAT_URL = `https://api-us.cometchat.io/v3`,

  REACT_APP_COMETCHAT_AUTH_KEY = 'b42de10188623ca7ca177f0f8bee464f58a5f126',
  REACT_APP_COMETCHAT_API_KEY = 'fc9d976c7f584daf098708664140f8c273e38838',
  REACT_APP_COMETCHAT_APP_ID = '30363adb6ae2efb',

  REACT_APP_COMETCHAT_COMMUNITY_AUTH_KEY = '711923bff3c978b861c7e3e0ef4e4d51f5862773',
  REACT_APP_COMETCHAT_COMMUNITY_API_KEY = '36bbbfb848eb31d51d50474387d8df7ef8b87457',
  REACT_APP_COMETCHAT_COMMUNITY_APP_ID = '2522813ab3aa9bc2',
}

export type CurrentChatUserType = CometChat.User & {
  unreadMessageCount?: number;
  unreadGroupMessageCount?: number;
  unreadUsersMessageCount?: number;
};

export interface IUssdTransactionStatus {
  invoiceID: string;
  isSuccess: boolean;
  errorMessage: string;
}
export interface IPersonOption {
  fullName: string;
  phrDependantID: string;
  relation?: string;
  userDetailID?: string;
  patientID: string;
  countryID: string;
  provinceStateID?: string;
  requiresInsurance: boolean;
  patient: IPatient;
}

export interface IUpdateProfile {
  title?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
}

export interface ISendInfo {
  sendSubject: string;
  sendMessage: string;
  sendToID?: string;
  sendToEnum: SendToEnum;
  sendCopyToPatient?: boolean;
}

export const COOKIE_NAME = 'Outpost.Health';

export const GLOBAL_MAX_TEXT_LENGTH = 10000;

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export const PASSWORD_REGEX = '^((?=.*\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\\W).{8,})$';

export const INITIAL_COUNTRY = 'ca';

export enum TaskItemTypeNamesEnum {
  PHRMedicationTaskItemClient = 'OH.APP.BusinessServices.TaskItems.PHRMedicationTaskItemClient',
  GeneratePrescriptionTaskItemClient = 'OH.APP.BusinessServices.TaskItems.GeneratePrescriptionTaskItemClient',
}

export enum PictureType {
  Discussions = 'Discussions',
  Profile = 'Profile',
  Dependant = 'Dependant',
}

export enum SystemSettingsEnum {
  SystemURL = 'SystemURL',
  SendEmail = 'SendEmail',
  EmailServer = 'EmailServer',
  EmailServerUserID = 'EmailServerUserID',
  EmailServerPassword = 'EmailServerPassword',
  EmailFromAddress = 'EmailFromAddress',
  ValidateEmailSubject = 'ValidateEmailSubject',
  ValidateEmailBody = 'ValidateEmailBody',
  ResetPasswordEmailSubject = 'ResetPasswordEmailSubject',
  ResetPasswordEmailBody = 'ResetPasswordEmailBody',
  AzureBlobConnectionString = 'AzureBlobConnectionString',
  APIKEY = 'APIKEY',
  SupportedLanguages = 'SupportedLanguages',
  ProviderWelcomeEmailSubject = 'ProviderWelcomeSubject',
  ProviderWelcomeEmailBody = 'ProviderWelcomeEmailBody',
  ClinicalTrialSignupToEmailAddress = 'ClinicalTrialSignupToEmailAddress',
  ClinicalTrialSignupSubject = 'ClinicalTrialSignupSubject',
  ClinicalTrialSignupBody = 'ClinicalTrialSignupBody',
  SupportEmailAddress = 'SupportEmailAddress',
  OrganizationStoreCategoryEnum = 'OrganizationStoreCategoryEnum',
  ShowOrganizations = 'ShowOrganizations',
  AllowedFileExtensions = 'AllowedFileExtensions',
  AllowedMimeTypes = 'AllowedMimeTypes',
  TestPatientEmailAddress = 'TestPatientEmailAddress',
}

export enum MailFoldersEnum {
  Inbox = 'Inbox',
  Sent = 'Sent',
  Deleted = 'Deleted',
}

export enum VirtualClinicVisitType {
  Appointment = 'Appointment',
  Now = 'Now',
}

export enum UserTypeEnum {
  Patient = 'Patient',
  Provider = 'Provider',
}

//TODO: GET API KEY
export const API_KEY = '7A4D651F-3784-400B-8563-A96D85F6D657';

export enum ThemeEnum {
  Primary = 'primary',
  Profile = 'profile',
  Empower = 'empower',
}

export enum ConsultTabTypeEnum {
  ConsultNotes = 0,
  Chat = 1,
  Prescriptions = 2,
  Orders = 3,
  // Documents = 4,
  Referrals = 4,
  Notes = 5,
}

export enum MyPatientsTabTypeEnum {
  Search = 0,
  Recent = 1,
  Waitlist = 2,
  Register = 3,
}

export enum TimeFormatEnum {
  LONG_DATE = 'LL', //November 15 2019
  SHORT_LONG_DATE = 'll', // Nov 15, 2019
  SHORT_DATE = 'l',
  TIME = 'LT', //1:18 PM
  SHORT_DATE_TIME = 'L LT', //11/15/2019 1:18 PM
  LONG_DATE_TIME = 'LL LT', //November 18, 2019 11:10 AM
  YEAR = 'YYYY', // 2019
}

export enum PHRAllergyTypes {
  EPI_PEN = 'epiPen',
  MEDICATIONS = 'allergicToMedications',
  ENVIRONMENTAL_SUBSTANCES = 'allergicToEnvironmentalSubstances',
  FOOD = 'allergicToFoods',
}

// React Select Styles
export const REACT_SELECT_GROUP_STYLES = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export enum AppFeatures {
  ClinicalTrials = 'CLINICAL_TRIAL',
  Wallet = 'Wallet',
  ProviderWalkInAvailable = 'ProviderWalkInAvailable',
  PatientMessagingEnabled = 'PatientMessagingEnabled',
  DiscussionsAvailable = 'DiscussionsAvailable',
  TwilioLogging = 'TwilioLogging',
  Mixpanel = 'MIXPANEL',
}

export interface IDefaultSelectOption {
  value: string;
  label: string;
}

export class NotFoundException extends Error {
  constructor(message: string) {
    super(message);
    this.name = '';
  }
}

export class UnauthorizedException extends Error {
  constructor(message: string) {
    super(message);
    this.name = '';
  }
}

// ChartJS Config

export const CHART_PLUGINS = {
  zoom: {
    pan: {
      enabled: true,
      mode: 'xy',
    },
    zoom: {
      enabled: true,
      mode: 'xy',
    },
  },
};

export interface IStoreItemAddOn {
  storeItem: IStoreItem;
  name: string;
  email: string;
}

export interface CreditCardInformation {
  cardNumber: string | undefined;
  expiryDate: string | undefined;
  cvv: string | undefined;
  keepCardOnFile: boolean;
}

export enum NavTheme {
  Dashboard = 'Dashboard',
  Heal = 'Heal',
  Monitor = 'Monitor',
  Learn = 'Learn',
  Profile = 'Profile',
  MessageCenter = 'MessageCenter',
  Wallet = 'Wallet',
}

export enum SystemMessageType {
  info = 'info',
  warning = 'warning',
  danger = 'danger',
}

export enum ScreenSizes {
  mobileL = '425px',
  tablet = '768px',
  laptop = '1024px',
  laptopL = '1440px',
}

export enum Device {
  mobileL = `(min-width: 425px)`,
  tablet = `(min-width: 768px)`,
  laptop = `(min-width: 1024px)`,
  laptopL = `(min-width: 1440px)`,
}

export enum SubMenuEnum {
  Admin = 'admin',
  Learning = 'learning',
  Heal = 'heal',
  Locums = 'locums',
  Community = 'community',
}

export enum SignInRoutes {
  SignIn = '/signin',
  SignUpType = '/signup/type',
  SignUpTypeEmail = '/signup/type/email',
  SignUpTypeHealthCard = '/signup/type/healthcard',
  SignUpTypeNotInitializedPatient = '/signup/type/patient/init',
  ProviderSignUp = '/provider/signup',
  ResetPassword = '/resetpassword',
  SignOut = '/signout',
}

export enum PublicSearchRoutes {
  Search = '/network',
  ProviderDetails = '/network/:countryID/:providerID',
  PublicPage = '/network/:publicTag',
  PublicPageBooking = '/network/booking/:publicTag',
  Locums = '/locums',
  SearchByLocation = '/network/location',
}

export enum PublicCommunityRoutes {
  Community = '/public/community',
  CommunityDiscussion = '/public/community/discussions/:discussionID',
  CommunitySearchResults = '/public/community/search',
}

export enum DashboardRoutes {
  Dashboard = '/',
  Submenu = '/menu',
  BlogPost = '/blog/:itemID',
  CoronavirusTool = '/coronavirus/assessment',
  AcceptConsult = '/AcceptConsult',
}

export enum ProviderOnboardingRoutes {
  SubmitDocuments = '/onboarding/submitDocuments',
}

export enum MerchantProvider {
  Tasks = '/merchant/tasks',
  InvoiceBuilder = '/merchant/invoiceBuilder',
  StoreManager = '/storemanager',
  Statements = '/merchant/statements',
  PayoutSettings = '/merchant/statements/payoutsettings',
  DownloadStatements = '/merchant/statements/downloadstatements',
  TransactionHistory = '/merchant/statements/transactionhistory',
}

export enum StoreManagerRoutes {
  Schedule = '/schedule',
}

export enum ProfileRoutes {
  PatientProfile = '/profile',
  PatientProfileAboutMe = '/profile/aboutme',
  PatientProfileContactInfo = '/profile/contactinfo',
  Dependants = '/profile/dependants',
  PurchaseHistory = '/profile/purchasehistory',
  Subscriptions = '/profile/subscriptions',
  SubscriptionDetails = '/profile/subscriptions/:subscriptionID',
  Preferences = '/profile/preferences',
  Relationships = '/profile/relationships',
  NewRelationship = '/profile/relationships/new',
  RelationshipActiveList = '/profile/relationships/active',
  RelationshipPendingList = '/profile/relationships/pending',
  Insurances = '/profile/insurances',
  PreferredPharmacy = '/profile/preferredpharmacy',
  PreferredLab = '/profile/preferredlab',

  ProviderProfile = '/providerprofile',
  ProviderProfilePreferences = '/providerprofile/preferences',
  ProviderProfileAboutMe = '/providerprofile/aboutme',
  ProviderProfilePublic = '/providerprofile/public',
  ProviderProfileVerification = '/providerprofile/verification',
  ProviderProfilePractices = '/providerprofile/practices',

  PatientProfileEmergencyContact = '/myprofile/patientprofile/emergencycontact',
  PatientProfileNotifications = '/myprofile/patientprofile/notifications',
  PatientProfileAccountInformation = '/myprofile/patientprofile/accountinformation',
  PatientCommunityProfile = '/myprofile/patientprofile/communityprofile',
  ProviderProfileSignature = '/myprofile/providerprofile/signature',
  ProviderCommunityProfile = '/myprofile/providerprofile/communityprofile',
  ProviderProfileAccountInformation = '/myprofile/providerprofile/accountinformation',
  ProviderProfileContactInfo = '/myprofile/providerprofile/contactinfo',
  ProviderProfileBankInformation = '/myprofile/providerprofile/bankinformation',
  ProviderProfileCard = '/myprofile/providerprofile/card',
  ProviderProfileEMR = '/myprofile/providerprofile/emr',
  ProviderProfilePublicProfile = '/myprofile/providerprofile/public',
}

export enum WalletRoutes {
  Wallet = '/wallet',
  WalletBalance = '/wallet/balance',
  WalletAddFunds = '/wallet/addFunds',
  WalletSendFunds = '/wallet/sendFunds',
  WalletUSSD = '/wallet/ussd',
  WalletPayees = '/wallet/payees',
  WalletTransactions = '/wallet/transactions',
  WalletDetails = '/wallet/:walletId',
}

export enum LearnRoutes {
  Community = 'https://devcommunity.neo.health/',
  CommunityDiscussion = '/learn/community/discussions/:discussionID',
  CommunityDiscussionCreate = '/learn/community/discussions/:discussionID/create',
  CommunityDiscussionItem = '/learn/community/discussions/:discussionID/discussion/:discussionItemID',
  CommunitySearchResults = '/learn/community/search',
  Coaching = '/learn/coaching',
  Education = '/learn/education',
  Surveys = '/learn/surveys',
  News = '/learn/news',
  CareSpaces = '/learn/carespaces',
  Module = '/learn/carespaces/modules/:moduleID',
  ModuleResource = '/learn/carespaces/modules/:moduleID/resources/:moduleResourceID',
}

export enum LocumsRoutes {
  Home = '/locums/:menu',
}

export enum ConsultRoutes {
  Consult = '/heal/virtualclinic/consult/:consultID',
}

export enum MyNetworkRoutes {
  HomePage = '/my-network',
}

export enum HealRoutes {
  BookConsult = '/heal/bookconsult',
  HealthRecords = '/heal/records',
  HealthRecordsFull = '/heal/health-records',
  VirtualClinic = '/heal/virtualclinic/',
  ClinicQueue = '/heal/virtualclinic/queue/:consultID',
  Clinic = '/heal/clinic',
  WaitingRoom = '/heal/clinic/waitingroom',
  Incomplete = '/heal/clinic/incomplete',
  PreceptorConsults = '/heal/clinic/preceptorconsults',
  ProviderConsultSessions = '/heal/clinic/consultsessions',
  ProviderConsultAttestations = '/heal/clinic/residentconsults',
  ProviderMyDay = '/heal/clinic/myday',
  ProviderTests = '/heal/clinic/runtests',
  ProviderSchedule = '/heal/clinic/schedule',
  ProviderAppointments = '/heal/provider/appointments',
  MyPatients = '/heal/clinic/mypatients',
  MyClinicSettings = '/heal/clinic/settings',
  MyClinicSettingsPractices = '/heal/clinic/settings/practices',
  CareTeam = '/heal/careteam',
  //Receptionist Specific
  ReceptionistDesk = '/heal/desk',
  ReceptionistWaitingRoom = '/heal/desk/waitingroom',
  ReceptionistPatientRegistration = '/heal/desk/patient/register',
  ReceptionistPatientSearch = '/heal/desk/patient/search',
  ReceptionistPatientVitals = '/heal/desk/patient/search/:patientID/vitals',
  ReceptionistProviderSearch = '/heal/desk/provider/search',
  ReceptionistCareHome = '/heal/desk/provider/carehome',
  ReceiptionistPatientCheckin = '/heal/desk/patient/checkin',
  ShoppingCart = '/heal/shoppingcart',
  Fax = '/heal/fax',
  FaxIncoming = '/heal/fax/incoming',
  FaxForwarded = '/heal/fax/forwarded',
  FaxDeleted = '/heal/fax/deleted',
  FaxOutbound = '/heal/fax/outbound',
  ClinicalCart = '/heal/clinicalCart',
  ClinicalOrder = '/heal/clinicalOrder',
  SelfLabOrder = '/heal/selflaborder',
}

export enum MonitorRoutes {
  Trackers = '/monitor/trackers',
  Timeline = '/monitor/timeline',
  ClinicalTrials = '/monitor/clinicaltrials',
  ClinicalTrial = '/monitor/clinicaltrials/:clinicalTrialID',
  ParticipateClinicalTrial = '/monitor/clinicaltrials/clinicaltrial/:clinicalTrialID/participate',
  HealthJournal = '/monitor/healthjournal',
  MoodTrackers = '/monitor/moodtrackers',
  Vitals = '/monitor/vitals',
  AddressBook = '/monitor/addressbook',
  ReferralHub = '/referralhub',
  Statements = '/statements',
  PayoutSettings = '/statements/payoutsettings',
  DownloadStatements = '/statements/downloadstatements',
  TransactionHistory = '/statements/transactionhistory',
}

export enum MessageCenterRoutes {
  Mail = '/mail',
  InboxMail = '/mail/inbox',
  SentMail = '/mail/sent',
  ComposeMail = '/mail/compose',
  DeletedMail = '/mail/deleted',
  AddressBook = '/mail/addressBook', // TODO: temporary page. remove this when address book user stories
}

export enum SupportCenterRoutes {
  Support = '/support',
}

export enum DefaultRoutes {
  NotFound = '/404',
  Unauthorized = '/403',
}

export enum CampaignRoutes {
  CampaignDetails = '/campaigns/:marketingCampaignID',
  GiveTheGift = '/givethegift',
}

export enum DelegateRoutes {
  DelegateRedirect = '/delegateredirect/:userDetailID',
  Redirect = '/redirect',
}

export enum ExternalRoutes {
  PaymentLink = '/paymentlink/:storeItemID',
}

export enum ButtonSizeEnum {
  extrasmall = 'xs',
  small = 'sm',
  medium = 'md',
  large = 'lg',
  full = 'full',
}

export enum FontFamilies {
  Primary = 'Inter',
}

export const GlobalStyle = createGlobalStyle`
  .st-btn {
    border-radius: 10px !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    font-family: ${FontFamilies.Primary} !important;
  }

  html {
    height: 100%;
    width: 100%;
    font-family: 'Inter', sans-serif;
    font-display: swap;
    background-color: white;
    color: ${OutpostTheme.FontColors.Primary};
    -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
  }

  body {
    height: 100%;
    width: 100%;
    background-color: ${OutpostTheme.Colors.BackgroundColor};
    overscroll-behavior: none;
  }

  p {
    margin: 0;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  .__react_component_tooltip  {
    z-index: 9999 !important;
  }

  button { 
    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }

  input:disabled {
    cursor: not-allowed;
  }

  .react-tiny-popover-container {
    z-index: 1500;
    border-radius: 5px;
  }

  .ReactCollapse--collapse {
    transition: height 300ms;
  }
`;

interface RouteConfig {
  path: string;
  title: string;
  subTitle?: string;
  breadcrumb: React.ComponentType | React.ElementType | string | null;
}

export enum PathParamKeysEnum {
  SubscriptionID = ':subscriptionID',
  PatientID = ':patientID',
  DiscussionID = ':discussionID',
  DiscussionItemID = ':discussionItemID',
  ClinicalTrialID = ':clinicalTrialID',
  TaskItemID = ':taskItemID',
  ItemID = ':itemID',
  MarketingCampaignID = ':marketingCampaignID',
}

export const RouteConfigs: RouteConfig[] = [
  {
    path: DashboardRoutes.Dashboard,
    title: 'Dashboard',
    breadcrumb: 'Dashboard',
  },
  {
    path: DashboardRoutes.AcceptConsult,
    title: 'Accepting Consult',
    breadcrumb: 'Accept',
  },
  {
    path: DashboardRoutes.BlogPost,
    title: 'Blog Post',
    breadcrumb: () => ConnectedBreadcrumb(PathParamKeysEnum.ItemID),
  },
  {
    path: MerchantProvider.Tasks,
    title: 'Manage Tasks',
    breadcrumb: 'Tasks',
  },
  {
    path: MerchantProvider.StoreManager,
    title: 'Store Manager',
    breadcrumb: 'Store Manager',
  },
  {
    path: ProfileRoutes.PatientProfile,
    title: `Profile`,
    breadcrumb: 'Profile',
  },
  {
    path: ProfileRoutes.PatientProfileAboutMe,
    title: `Profile`,
    breadcrumb: 'About Me',
  },
  {
    path: ProfileRoutes.PatientProfileContactInfo,
    title: `Profile`,
    breadcrumb: 'Contact Info',
  },
  {
    path: ProfileRoutes.PatientProfileEmergencyContact,
    title: `Profile`,
    breadcrumb: 'Emergency Contact',
  },
  {
    path: ProfileRoutes.PatientProfileContactInfo,
    title: `Profile`,
    breadcrumb: 'Contact Info',
  },
  {
    path: ProfileRoutes.PatientProfileNotifications,
    title: `Profile`,
    breadcrumb: 'Notifications',
  },
  {
    path: ProfileRoutes.PatientProfileAccountInformation,
    title: `Profile`,
    breadcrumb: 'Account Information',
  },
  {
    path: ProfileRoutes.PatientCommunityProfile,
    title: `Profile`,
    breadcrumb: 'Community Profile',
  },
  {
    path: ProfileRoutes.ProviderProfile,
    title: `Profile`,
    breadcrumb: 'Profile',
  },
  {
    path: ProfileRoutes.ProviderProfileAboutMe,
    title: `Profile`,
    breadcrumb: 'About Me',
  },
  {
    path: ProfileRoutes.ProviderProfilePublic,
    title: `Public Profile`,
    breadcrumb: 'Public Profile',
  },
  {
    path: ProfileRoutes.ProviderProfileVerification,
    title: `Verification`,
    breadcrumb: 'Verification',
  },
  {
    path: ProfileRoutes.ProviderProfilePractices,
    title: `Practices`,
    breadcrumb: 'Practices',
  },
  {
    path: ProfileRoutes.ProviderProfileContactInfo,
    title: `Profile`,
    breadcrumb: 'Contact Info',
  },
  {
    path: ProfileRoutes.ProviderProfileSignature,
    title: `Profile`,
    breadcrumb: 'Signature',
  },
  {
    path: ProfileRoutes.ProviderCommunityProfile,
    title: `Profile`,
    breadcrumb: 'Community Profile',
  },
  {
    path: ProfileRoutes.ProviderProfileAccountInformation,
    title: `Profile`,
    breadcrumb: 'Account Information',
  },
  {
    path: ProfileRoutes.ProviderProfileBankInformation,
    title: `Profile`,
    breadcrumb: 'Bank Information',
  },
  {
    path: ProfileRoutes.ProviderProfileCard,
    title: `Profile`,
    breadcrumb: 'Card',
  },
  {
    path: ProfileRoutes.ProviderProfilePublicProfile,
    title: `Profile`,
    breadcrumb: 'Public',
  },
  {
    path: ProfileRoutes.ProviderProfileEMR,
    title: `Profile`,
    breadcrumb: 'EMR',
  },
  {
    path: ProfileRoutes.Dependants,
    title: `Your Family`,
    breadcrumb: 'Family',
  },
  {
    path: ProfileRoutes.PurchaseHistory,
    title: `Purchase History`,
    breadcrumb: 'Purchase History',
  },
  {
    path: ProfileRoutes.Relationships,
    title: `Member Plans`,
    breadcrumb: 'Member Plans',
  },
  {
    path: HealRoutes.ShoppingCart,
    title: `Clinical Cart`,
    breadcrumb: 'Clinical Cart',
  },
  {
    path: HealRoutes.Fax,
    title: `Faxes`,
    breadcrumb: 'Faxes',
  },
  {
    path: HealRoutes.FaxIncoming,
    title: `Faxes`,
    breadcrumb: 'Incoming',
  },
  {
    path: HealRoutes.FaxForwarded,
    title: `Faxes`,
    breadcrumb: 'Forwarded',
  },
  {
    path: HealRoutes.FaxDeleted,
    title: `Faxes`,
    breadcrumb: 'Deleted',
  },
  {
    path: ProfileRoutes.Subscriptions,
    title: `Member Plans`,
    breadcrumb: 'Member Plans',
  },
  {
    path: ProfileRoutes.SubscriptionDetails,
    title: `Member Plans`,
    breadcrumb: () => ConnectedBreadcrumb(PathParamKeysEnum.SubscriptionID),
  },
  {
    path: ProfileRoutes.Preferences,
    title: `Settings`,
    breadcrumb: 'Settings',
  },
  {
    path: ProfileRoutes.Insurances,
    title: `Insurance`,
    breadcrumb: 'Insurance',
  },
  {
    path: WalletRoutes.Wallet,
    title: `Health Wallet`,
    breadcrumb: 'Health Wallet',
  },
  {
    path: WalletRoutes.WalletPayees,
    title: `Health Wallet`,
    breadcrumb: 'Payees',
  },
  {
    path: WalletRoutes.WalletTransactions,
    title: `Health Wallet`,
    breadcrumb: 'Transactions',
  },
  {
    path: WalletRoutes.WalletDetails,
    title: `Health Wallet`,
    breadcrumb: 'Details',
  },
  {
    path: MessageCenterRoutes.Mail,
    title: 'Mail',
    breadcrumb: 'Mail',
  },
  {
    path: MessageCenterRoutes.InboxMail,
    title: `Messages`,
    breadcrumb: 'Inbox',
  },
  {
    path: MessageCenterRoutes.DeletedMail,
    title: `Messages`,
    breadcrumb: 'Deleted',
  },
  {
    path: MessageCenterRoutes.ComposeMail,
    title: `Messages`,
    breadcrumb: 'Compose',
  },
  {
    path: MessageCenterRoutes.SentMail,
    title: `Messages`,
    breadcrumb: 'Sent',
  },
  {
    path: MessageCenterRoutes.AddressBook,
    title: `Messages`,
    breadcrumb: 'Address Book',
  },
  {
    path: SupportCenterRoutes.Support,
    title: `Help Center`,
    breadcrumb: 'Help Center',
  },
  {
    path: HealRoutes.HealthRecords,
    title: `Medical Records`,
    breadcrumb: 'Medical Records',
  },
  {
    path: HealRoutes.HealthRecordsFull,
    title: `Medical Records`,
    breadcrumb: 'Medical Records',
  },
  {
    path: HealRoutes.VirtualClinic,
    title: `Virtual Clinic`,
    breadcrumb: 'Virtual Clinic',
  },
  {
    path: HealRoutes.PreceptorConsults,
    title: `Preceptor Consults`,
    breadcrumb: 'Preceptor Consults',
  },
  {
    path: HealRoutes.BookConsult,
    title: `Book Consult`,
    breadcrumb: 'Book Consult',
  },
  {
    path: HealRoutes.Clinic,
    title: `Welcome to your Clinic,`,
    breadcrumb: 'My Clinic',
  },
  {
    path: HealRoutes.MyClinicSettings,
    title: `Clinic Settings`,
    breadcrumb: 'Settings',
  },
  {
    path: HealRoutes.WaitingRoom,
    title: `Waiting Room`,
    breadcrumb: 'Waiting Room',
  },
  {
    path: HealRoutes.Incomplete,
    title: `Incomplete Consults`,
    breadcrumb: 'Incomplete Consults',
  },
  {
    path: HealRoutes.ProviderTests,
    title: `Run Video & Audio Test`,
    breadcrumb: 'Video & Audio Test',
  },
  {
    path: HealRoutes.ProviderConsultSessions,
    title: `Consult Sessions`,
    breadcrumb: 'Consult Sessions',
  },
  {
    path: HealRoutes.ProviderMyDay,
    title: `Day Sheet`,
    breadcrumb: 'Day Sheet',
  },
  {
    path: HealRoutes.ProviderConsultAttestations,
    title: `Resident Consults`,
    breadcrumb: 'Resident Consults',
  },
  {
    path: HealRoutes.ProviderSchedule,
    title: `Calendar`,
    breadcrumb: 'Calendar',
  },
  {
    path: HealRoutes.ProviderAppointments,
    title: `Appointments`,
    breadcrumb: 'Appointments',
  },
  {
    path: HealRoutes.MyPatients,
    title: `My Patients`,
    breadcrumb: 'My Patients',
  },
  {
    path: HealRoutes.ReceptionistDesk,
    title: 'My Desk',
    breadcrumb: 'My Desk',
  },
  {
    path: HealRoutes.ReceptionistWaitingRoom,
    title: `Waiting Room`,
    breadcrumb: 'Waiting Room',
  },
  {
    path: HealRoutes.ReceptionistPatientRegistration,
    title: `Patient Registration`,
    breadcrumb: 'Patient Registration',
  },
  {
    path: HealRoutes.ReceiptionistPatientCheckin,
    title: `Patient Check-in`,
    breadcrumb: 'Patient Check-in',
  },
  {
    path: HealRoutes.ReceptionistPatientSearch,
    title: `Patient Search`,
    breadcrumb: 'Patient Search',
  },
  {
    path: HealRoutes.ReceptionistProviderSearch,
    title: `My Providers`,
    breadcrumb: 'My Providers',
  },
  {
    path: HealRoutes.CareTeam,
    title: `Your Care Team`,
    breadcrumb: 'Care Team',
  },
  {
    path: LearnRoutes.Community,
    title: `Community Forum`,
    breadcrumb: 'Community Forum',
  },
  {
    path: LearnRoutes.CommunitySearchResults,
    title: `Community Forum`,
    breadcrumb: 'Community Search',
  },
  {
    path: LearnRoutes.CommunityDiscussion,
    title: `Community Forum`,
    breadcrumb: () => ConnectedBreadcrumb(PathParamKeysEnum.DiscussionID),
  },
  {
    path: LearnRoutes.CommunityDiscussionCreate,
    title: `Community Forum`,
    breadcrumb: 'Create',
  },
  {
    path: LearnRoutes.CommunityDiscussionItem,
    title: `Community Forum`,
    breadcrumb: () => ConnectedBreadcrumb(PathParamKeysEnum.DiscussionItemID),
  },
  {
    path: LearnRoutes.CareSpaces,
    title: `Health Resources`,
    breadcrumb: `Health Resources`,
  },
  {
    path: HealRoutes.ClinicQueue,
    title: `This is Your Waiting Room`,
    breadcrumb: 'Waiting Room',
  },
  {
    path: MonitorRoutes.ClinicalTrials,
    title: `Clinical Trials`,
    breadcrumb: 'Clinical Trials',
  },
  {
    path: MonitorRoutes.ClinicalTrial,
    title: `Clinical Trials`,
    breadcrumb: () => ConnectedBreadcrumb(PathParamKeysEnum.ClinicalTrialID),
  },
  {
    path: MonitorRoutes.ParticipateClinicalTrial,
    title: `Clinical Trials`,
    breadcrumb: 'Participate',
  },
  {
    path: MonitorRoutes.HealthJournal,
    title: `Health Journal`,
    breadcrumb: 'Health Journal',
  },
  {
    path: MonitorRoutes.Vitals,
    title: `Vitals`,
    breadcrumb: 'Vitals',
  },
  {
    path: MonitorRoutes.MoodTrackers,
    title: `Mood Trackers`,
    breadcrumb: 'Mood Trackers',
  },
  {
    path: MonitorRoutes.AddressBook,
    title: `Address Book`,
    breadcrumb: 'Address Book',
  },
  {
    path: MonitorRoutes.ReferralHub,
    title: `Referral Hub`,
    breadcrumb: 'Referral Hub',
  },
  {
    path: MonitorRoutes.Statements,
    title: 'Statements',
    breadcrumb: 'Statements',
  },
  {
    path: MonitorRoutes.PayoutSettings,
    title: 'Payout Settings',
    breadcrumb: 'Payout Settings',
  },
  {
    path: MonitorRoutes.DownloadStatements,
    title: 'Download Statements',
    breadcrumb: 'Download Statements',
  },
  {
    path: MonitorRoutes.TransactionHistory,
    title: 'Transaction History',
    breadcrumb: 'Transaction History',
  },
  {
    path: MerchantProvider.Statements,
    title: 'Statements',
    breadcrumb: 'Statements',
  },
  {
    path: MerchantProvider.PayoutSettings,
    title: 'Payout Settings',
    breadcrumb: 'Payout Settings',
  },
  {
    path: MerchantProvider.DownloadStatements,
    title: 'Download Statements',
    breadcrumb: 'Download Statements',
  },
  {
    path: MerchantProvider.TransactionHistory,
    title: 'Transaction History',
    breadcrumb: 'Transaction History',
  },
  {
    path: CampaignRoutes.CampaignDetails,
    title: `Campaign`,
    breadcrumb: () => ConnectedBreadcrumb(PathParamKeysEnum.MarketingCampaignID),
  },
  {
    path: DefaultRoutes.Unauthorized,
    title: `Uh Oh`,
    breadcrumb: '403',
  },
  {
    path: ExternalRoutes.PaymentLink,
    title: `Lotus Gym BootCamp Payment`,
    breadcrumb: 'Lotus Gym BootCamp Payment',
  },
];

export const getReactSelectStyle = () => {
  return {
    // container: (styles) => ({
    //   ...styles,
    //   boxShadow: '0 !important',
    //   marginTop: '5px',
    // }),
    menu: (styles) => ({
      ...styles,
      zIndex: 1000,
    }),
    control: (styles, { isDisabled, isFocused }) => ({
      ...styles,
      backgroundColor: isDisabled
        ? 'rgb(228, 233, 242)'
        : styles.backgroundColor ?? 'rgb(247, 249, 252)',
      borderRadius: styles.borderRadius ?? '6px',
      borderColor: isFocused
        ? 'rgb(0, 3, 16) !important'
        : isDisabled
        ? '#f2f1f3'
        : styles.borderColor ?? 'rgba(12, 52, 104, 0.57)',
      color: isDisabled ? '#737373' : 'rgb(0, 3, 16)',
      minHeight: '40px',
      boxShadow: 'none',
      cursor: isDisabled ? 'not-allowed' : 'default',
    }),
    placeholder: (styles) => ({
      ...styles,
      color: 'rgb(120, 125, 154)',
      fontSize: '14px',
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: '#F4F6FA',
      borderRadius: '18px',
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: 'rgb(0, 3, 16)',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      '&:hover': {
        backgroundColor: '#C77777',
        color: 'white',
      },
    }),
    option: (styles, { data, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? 'rgb(0, 3, 16)' : isFocused ? 'rgb(247, 249, 252)' : null,
        ':active': {
          ...styles[':active'],
          backgroundColor: isSelected ? data.color : '#04064b',
          color: 'white',
        },
      };
    },
    valueContainer: (styles) => ({
      ...styles,
      paddingLeft: '1rem',
      paddingRight: '1rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    singleValue: (styles, { isDisabled }) => ({
      ...styles,
      color: isDisabled ? '#737373' : 'rgb(0, 3, 16)',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: 'rgb(0, 3, 16)',
    }),
    clearIndicator: (styles) => ({
      ...styles,
      color: 'rgb(0, 3, 16)',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
  };
};

export const getReactSelectUnStyled = () => {
  return {
    menu: (styles) => ({
      ...styles,
      zIndex: 1000,
    }),
    control: (styles, { isDisabled, isFocused }) => ({
      ...styles,
      backgroundColor: 'transparent',
      borderRadius: '6px',
      borderColor: isFocused ? 'rgb(0, 3, 16) !important' : 'transparent',
      boxShadow: 'none',
      color: isDisabled ? '#737373' : 'rgb(0, 3, 16)',
      minHeight: '40px',
      cursor: isDisabled ? 'not-allowed' : 'default',
    }),
    placeholder: (styles) => ({
      ...styles,
      color: 'rgb(120, 125, 154)',
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: '#F4F6FA',
      borderRadius: '18px',
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: 'rgb(0, 3, 16)',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      '&:hover': {
        backgroundColor: '#C77777',
        color: 'white',
      },
    }),
    option: (styles, { data, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? 'rgb(0, 3, 16)' : isFocused ? 'rgb(247, 249, 252)' : null,
        ':active': {
          ...styles[':active'],
          backgroundColor: isSelected ? data.color : '#04064b',
          color: 'white',
        },
      };
    },
    valueContainer: (styles) => ({
      ...styles,
      paddingLeft: '1rem',
      paddingRight: '1rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    singleValue: (styles, { isDisabled }) => ({
      ...styles,
      color: isDisabled ? '#737373' : 'rgb(0, 3, 16)',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: 'rgb(0, 3, 16)',
    }),
    clearIndicator: (styles) => ({
      ...styles,
      color: 'rgb(0, 3, 16)',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
  };
};

export enum AcceptEnvironment {
  Sandbox = 'SANDBOX',
  Production = 'PRODUCTION',
}

export enum SpacingRelated {
  Regular = '30px',
  HalfRegular = '15px',
  MobileRegular = '16px',
  HalfMobileRegular = '8px',
  Minimum = '10px',
  HalfMinimum = '5px',
}

export enum SpacingGroups {
  Minimum = '60px',
}

export enum SpacingSections {
  Minimum = '120px',
}

export enum Padding {
  Regular = '40px',
}

export enum BorderRadius {
  Buttons = '10px',
  Regular = '12px',
}

export enum ThumbnailTypeEnum {
  User = 'User',
  Clinic = 'Clinic',
  Organization = 'Organization',
}

export enum WeightHeightEnum {
  Imperial = 'Imperial',
  Metric = 'Metric',
}

export interface ITrackerWeightDTO extends PHRTrackerWeightHeightDTO {
  bmi?: number;
}

export const RECORDS_TO_TAKE = 10;
export const DEFAULT_QUERY_STALE_TIME = 10 * 60 * 1000; // 10 minutes

export enum PhoneEventsEnum {
  CONSULT_START = 'CONSULT_START',
  CONSULT_END = 'CONSULT_END',
  CANCEL_BOOKING = 'CANCEL_BOOKING',
  IS_IN_WALLET_HOME = 'IS_IN_WALLET_HOME',
  IS_IN_HELP_CENTER_HOME = 'IS_IN_HELP_CENTER_HOME',
  IS_NOT_IN_HELP_CENTER_HOME = 'IS_NOT_IN_HELP_CENTER_HOME',
  IS_NOT_IN_WALLET_HOME = 'IS_NOT_IN_WALLET_HOME',
  CONFIRM_APPOINTMENT = 'CONFIRM_APPOINTMENT',
  CANCELED_APPOINTMENT = 'CANCELED_APPOINTMENT',
  PAGE_ERROR_REDIRECT = 'PAGE_ERROR_REDIRECT',
  RETURN_TO_WAITING_ROOM = 'RETURN_TO_WAITING_ROOM',
  START_PROVIDER_CHAT = 'START_PROVIDER_CHAT',
  ENTER_CHAT_LIST = 'ENTER_CHAT_LIST',
  REQUEST_LOCATION_CALLBACK = 'REQUEST_LOCATION_CALLBACK',
  HOME_CARE_NURSE_PROVIDER_SEARCH_CALLBACK = 'HOME_CARE_NURSE_PROVIDER_SEARCH_CALLBACK',
  EXIT_EARNINGS_HOME = 'EXIT_EARNINGS_HOME',
  IS_IN_EARNINGS_HOME = 'IS_IN_EARNINGS_HOME',
  ADMIN_EXIT_CALLBACK_HANDLER = 'ADMIN_EXIT_CALLBACK_HANDLER',
  ADMIN_HOME_CALLBACK_HANDLER = 'ADMIN_HOME_CALLBACK_HANDLER',
  LOCUMS_EXIT_CALLBACK_HANDLER = 'LOCUMS_EXIT_CALLBACK_HANDLER',
  LOCUMS_HOME_CALLBACK_HANDLER = 'LOCUMS_HOME_CALLBACK_HANDLER',
  LEARNING_HOME_CALLBACK_HANDLER = 'LEARNING_HOME_CALLBACK_HANDLER',
  LEARNING_EXIT_CALLBACK_HANDLER = 'LEARNING_EXIT_CALLBACK_HANDLER',
  HEAL_HOME_CALLBACK_HANDLER = 'HEAL_HOME_CALLBACK_HANDLER',
  HEAL_EXIT_CALLBACK_HANDLER = 'HEAL_EXIT_CALLBACK_HANDLER',
  RESUME_CONSULT_CALLBACK_HANDLER = 'RESUME_CONSULT_CALLBACK_HANDLER',
  START_CONSULT_CALLBACK_HANDLER = 'START_CONSULT_CALLBACK_HANDLER',
  SIGN_UP_COMPLETE = 'SIGN_UP_COMPLETE',
  PROVIDER_DOCUMENTS_SUBMITTED = 'PROVIDER_DOCUMENTS_SUBMITTED',
  PUBLIC_SEARCH_UID_CALLBACK_HANDLER = 'PUBLIC_SEARCH_UID_CALLBACK_HANDLER',
  PUBLIC_BOOKING_UID_CALLBACK_HANDLER = 'PUBLIC_BOOKING_UID_CALLBACK_HANDLER',
  IS_IN_CARE_HOMES_HOME = 'IS_IN_CARE_HOMES_HOME',
  EXIT_CARE_HOME = 'EXIT_CARE_HOME',
  CURRENT_CART_TAB_CALLBACK = 'CURRENT_CART_TAB_CALLBACK',
  PAY_LATER_CART_TAB_CALLBACK = 'PAY_LATER_CART_TAB_CALLBACK',
  PAY_NOW_NEO_CALLBACK = 'PAY_NOW_NEO_CALLBACK',
  CANCEL_NEO_PAYMENT_CALLBACK = 'CANCEL_NEO_PAYMENT_CALLBACK',
  LAB_ORDER_CREATED_CALLBACK = 'LAB_ORDER_CREATED_CALLBACK',
  SHOW_REWARDS_QR_CALLBACK = 'SHOW_REWARDS_QR_CALLBACK',
  CLOSE_DIALOG_CALLBACK = 'CLOSE_DIALOG_CALLBACK',
  QUIT_BOOKING_PROCESS_CALLBACK = 'QUIT_BOOKING_PROCESS_CALLBACK',
  QUIT_SELF_ORDERING_CALLBACK = 'QUIT_SELF_ORDERING_CALLBACK',
  SELF_ORDERING_COMPLETE_CALLBACK = 'SELF_ORDERING_COMPLETE_CALLBACK',
  SHARE_MY_REFERRAL_CALLBACK = 'SHARE_MY_REFERRAL_CALLBACK',
  IOS_QUIT_SELF_ORDERING_CALLBACK = 'quitSelfOrderingCallback',
  IOS_SELF_ORDERING_COMPLETE_CALLBACK = 'selfOrderingCompleteCallback',
  IOS_SHARE_MY_REFERRAL_CALLBACK = 'shareMyReferralCallbackHandler',
  CANCEL_KYC_CALLBACK_HANDLER = 'CANCEL_KYC_CALLBACK_HANDLER',
  CANCEL_PAYMENT_LINK_HANDLER = 'CANCEL_PAYMENT_LINK_HANDLER',
  QUIT_PAYMENT_LINK_CALLBACK = 'QUIT_PAYMENT_LINK_CALLBACK',
  IOS_QUIT_PAYMENT_LINK_CALLBACK = 'IOS_QUIT_PAYMENT_LINK_CALLBACK',
  PAYMENT_LINK_COMPLETE_CALLBACK = 'PAYMENT_LINK_COMPLETE_CALLBACK',
  IOS_PAYMENT_LINK_COMPLETE_CALLBACK = 'IOS_PAYMENT_LINK_COMPLETE_CALLBACK',
  SHOW_WALLET_QR_CALLBACK = 'SHOW_WALLET_QR_CALLBACK',
  FORUM_HOME_CALLBACK_HANDLER = 'FORUM_HOME_CALLBACK_HANDLER',
  FORUM_EXIT_CALLBACK_HANDLER = 'FORUM_EXIT_CALLBACK_HANDLER',
}

export type YesNoRadio = 'yes' | 'no' | undefined;
