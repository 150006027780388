export const BASE_URL = '';

export const communityUrl = window.location.href.includes('demo')
  ? 'https://democommunity.neo.health/'
  : window.location.href.includes('dev')
  ? 'https://devcommunity.neo.health/'
  : 'https://community.neo.health/';

export const idUrl = window.location.href.includes('demo')
  ? 'https://demoid.neo.health'
  : window.location.href.includes('dev')
  ? 'https://devid.neo.health'
  : 'https://id.neo.health';

export const appUrl = window.location.href.includes('demo')
  ? 'https://demoapp.neo.health'
  : window.location.href.includes('dev')
  ? 'https://devapp.neo.health'
  : 'https://app.neo.health';
