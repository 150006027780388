import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { COOKIE_NAME, DashboardRoutes, DelegateRoutes, SignInRoutes } from '../constants';
import { RolesEnum } from '../generated';
import { ICurrentUser } from '../store/currentUser/currentUserReducers';
import { AppState } from '../store/root-reducers';
import { getCookie, setCookie } from '../utils/cookieHelper';
import { useDelegateFromStorage } from '../utils/hooks/useDelegateFromStorage';
import { isInRole } from '../utils/isInRole';
import { signIn } from '../store/root-creator';
import { IAuthenticateUserSignIn } from '../types';

interface IProps {
  exact?: boolean;
  roles?: RolesEnum[];
  notInRoles?: RolesEnum[];
  path?: string | string[];
  featureName?: string;
  component: React.ComponentType<any>;
}

const AuthenticatedRoute = ({
  component: Component,
  roles,
  notInRoles,
  featureName,
  ...rest
}: IProps) => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const dispatch = useDispatch();

  const currentUserHasRole = (roles: RolesEnum[], currentUser: ICurrentUser): boolean => {
    let hasRole = false;
    roles.forEach((role) => {
      if (isInRole(currentUser, role)) {
        hasRole = true;
      }
    });

    return hasRole;
  };

  useDelegateFromStorage({ currentUser });

  return (
    <Route
      {...rest}
      render={(props) => {
        if (currentUser === null || !getCookie(COOKIE_NAME)) {
          try {
            const { location } = props;
            const fromSearchParam = new URLSearchParams(location.search?.split('?')[1]);
            const authenticateSignIn: IAuthenticateUserSignIn = {};
            if (fromSearchParam.get('t')) {
              authenticateSignIn.t = fromSearchParam.get('t') as string;
              setCookie(COOKIE_NAME, authenticateSignIn.t!);
              fromSearchParam.delete('t');
            }
            if (fromSearchParam.get('u')) {
              authenticateSignIn.u = fromSearchParam.get('u') as string;
              fromSearchParam.delete('u');
            }
            location.search = fromSearchParam.toString();
            console.log('auth:::', { authenticateSignIn, location });

            if (window.location.pathname?.includes('/heal/health-records')) {
              if (authenticateSignIn && authenticateSignIn.t && authenticateSignIn.u) {
                (async () => {
                  await dispatch(signIn({}, undefined, authenticateSignIn));
                })();
                setTimeout(() => {
                  return <Redirect to={{ pathname: location.pathname }} />;
                }, 500);
              }
              return <Redirect to={{ pathname: DelegateRoutes.Redirect }} />;
            }
          } catch (error) {}

          return (
            <Redirect to={{ pathname: SignInRoutes.SignIn, state: { from: props.location } }} />
          );
        } else if (roles && !currentUserHasRole(roles, currentUser)) {
          // role not authorized
          return <Redirect to={{ pathname: DashboardRoutes.Dashboard }} />;
        } else if (notInRoles && currentUserHasRole(notInRoles, currentUser)) {
          // Has Role that does not allow them to have access
          return <Redirect to={{ pathname: DashboardRoutes.Dashboard }} />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default AuthenticatedRoute;
