import { Box, Center, Flex } from '@chakra-ui/react';
import React, { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';

import PatientActiveConsultInfoBanner from '../../components/ActiveConsultInfoBanner/PatientActiveConsultInfoBanner';
import ProviderActiveConsultInfoBanner from '../../components/ActiveConsultInfoBanner/ProviderActiveConsultInfoBanner';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { CenteredLoadingIndicator } from '../../components/LoadingIndicator';
import SystemMessagesBanner from '../../components/SystemMessagesBanner';
import UserDelegateBanner from '../../components/UserDelegateBanner';
import { RolesEnum } from '../../generated';
import { AppState } from '../../store/root-reducers';
import { isInRole } from '../../utils/isInRole';

import { OnboardingContextProvider } from '../onboarding/context/OnboardingContextProvider';

import ActiveConsultModal from './components/ActiveConsultModal';
import AppVersionMissmatchModal from './components/AppVersionMissmatchModal';
import { AuthenticatedPrerequisites } from './components/AuthenticatedPrerequisites/AuthenticatedPrerequisites';
import AutoLogout from './components/AutoLogout';
import { ChangePasswordModal } from './components/ChangePasswordModal';
import PageTitle from './components/PageTitle/PageTitle';
import Footer from './Footer/Footer';
import { HomeCareAdminSideMenu } from './Sidemenu/HomeCareAdminSideMenu';
import { MerchantSideMenu } from './Sidemenu/MerchantSideMenu';
import { PatientSideMenu } from './Sidemenu/PatientSideMenu';
import { ProviderSideMenu } from './Sidemenu/ProviderSideMenu';
import { ReceptionistSideMenu } from './Sidemenu/ReceptionistSideMenu';
import { StoreManagerSideMenu } from './Sidemenu/StoreManagerSideMenu';
import { NeoTopBar } from './Sidemenu/components/NeoTopBar';
import { useLocation } from 'react-router';

interface IProps {
  hasError?: boolean;
  hideNav?: boolean;
  children: any;
}

const AuthenticatedLayout = ({ hasError = false, hideNav = false, children }: IProps) => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const isMenuOpen = useSelector((state: AppState) => state.sideMenuState.isOpen);
  const { consult: activeConsult } = useSelector((state: AppState) => state.activeConsultState);
  const routeLocation = useLocation();

  useEffect(() => {
    if (
      ['health', 'devhealth', 'demohealth'].includes(window.location.hostname?.split('.')[0]) &&
      !routeLocation.pathname?.includes('/heal/health-records')
    ) {
      window.location.href = '/heal/health-records';
    }
  }, [routeLocation.pathname]);

  return hasError ? (
    <Center minHeight="100vh">{children}</Center>
  ) : (
    <AuthenticatedPrerequisites>
      <PageTitle />
      {isInRole(currentUser, RolesEnum.NotApprovedProvider) ? (
        <OnboardingContextProvider>{children}</OnboardingContextProvider>
      ) : (
        <React.Fragment>
          <Flex maxW="1535px" m="0 auto" backgroundColor="#f9f9f9" pb={!hideNav ? '4rem' : 0}>
            {!hideNav ? (
              !currentUser?.isInMobileView ? (
                isInRole(currentUser, RolesEnum.Patient) ? (
                  <PatientSideMenu />
                ) : isInRole(currentUser, RolesEnum.MerchantProvider) ? (
                  <MerchantSideMenu />
                ) : isInRole(currentUser, RolesEnum.StoreManager) ? (
                  <StoreManagerSideMenu />
                ) : isInRole(currentUser, RolesEnum.HomeCareAdministrator) ? (
                  <HomeCareAdminSideMenu />
                ) : isInRole(currentUser!, RolesEnum.Receptionist) ? (
                  <ReceptionistSideMenu />
                ) : isInRole(currentUser, RolesEnum.Provider) ? (
                  <ProviderSideMenu />
                ) : null
              ) : null
            ) : (
              <NeoTopBar />
            )}
            <Box
              flexGrow={1}
              width={{ base: 0, lg: '100%' }}
              ml={{ base: 0, lg: !hideNav ? (isMenuOpen ? '230px' : '60px') : 0 }}
              transition=".2s ease"
            >
              <Box
                mx={[2, 4, 8, 10]}
                pt={
                  !hideNav
                    ? currentUser?.isInMobileView
                      ? 0
                      : ['5rem', '5.5rem', '6rem', '6.5rem']
                    : 0
                }
                flexGrow={1}
                minH="100vh"
              >
                {/* <TopBar /> */}
                {isInRole(currentUser, RolesEnum.Provider) && <ProviderActiveConsultInfoBanner />}
                {!currentUser?.isInMobileView && (
                  <>
                    {isInRole(currentUser, RolesEnum.Patient) && (
                      <PatientActiveConsultInfoBanner currentUser={currentUser!} />
                    )}
                    <SystemMessagesBanner />
                    <UserDelegateBanner />
                  </>
                )}
                <Suspense fallback={<CenteredLoadingIndicator />}>
                  {!currentUser?.isInMobileView && !hideNav && (
                    <Breadcrumbs mb={2} display={{ base: 'block', lg: 'none' }} />
                  )}
                  {children}
                </Suspense>
              </Box>
            </Box>
          </Flex>
          {!currentUser?.isInMobileView && (
            <>
              <AutoLogout />
              <AppVersionMissmatchModal />
            </>
          )}
          {!currentUser?.isInMobileView && !hideNav && (
            <>
              <Footer />
              <ActiveConsultModal activeConsult={activeConsult} />
              <ChangePasswordModal />
            </>
          )}
        </React.Fragment>
      )}
    </AuthenticatedPrerequisites>
  );
};

export default AuthenticatedLayout;
